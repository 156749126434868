import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "policy-configuration"
    }}>{`Policy configuration`}</h1>
    <h2 {...{
      "id": "add-a-new-policy"
    }}>{`Add a new policy`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Settings" from the navigation bar.`}</li>
      <li parentName="ol">{`Choose "Policies" tab and click "Create new" button.
`}<img alt="Policies" src={require("../../assets/configure/policies/policies-list.png")} /></li>
      <li parentName="ol">{`Enter all required fields to the policy form.`}</li>
    </ol>
    <p>{`RPO type policy:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Policy name`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Custom name of the policy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove primary snapshots after resource deletion`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Removes primary snapshots after the resource is deleted from AutoBackup. Can be set/visible if the `}<strong parentName="td"><em parentName="strong">{`Primary snapshot condition`}</em></strong>{` has been set to DAYS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove replica snapshots after resource deletion`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Removes replica snapshots after the resource is deleted from AutoBackup. Can be set/visible if the `}<strong parentName="td"><em parentName="strong">{`Replica snapshot condition`}</em></strong>{` has been set to DAYS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Snapshot frequency*`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Recovery Point Objective expressed in hours/days/weeks/months. It specifies how often the backups should be performed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary snapshot condition`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines if primary snapshots should be removed after the specified number of days, or if the system should retain only the specified number of the newest snapshots.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary snapshot threshold`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`How many OR For how long the replica snapshots are retained.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Replica snapshot condition`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines if replica snapshots should be removed after the specified number of days, or if the system should retain only the specified number of the newest snapshots.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Replica snapshot threshold`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`How many OR For how long the replica snapshots are retained.`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`*Please note that Azure does not allow creating manual snapshots with arbitrary frequency. At most 1 snapshot can be taken per 12 hours. Therefore, RPO for Azure resources can't be less than 13h, since AutoBackup schedules backup events for RPO - 1h (only for RPO type policy).`}</p>
    </blockquote>
    <p>{`Time aware type policy:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Policy name`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Custom name of the policy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove primary snapshots after resource deletion`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Removes primary snapshots after the resource is deleted from AutoBackup. Can be set/visible if the `}<strong parentName="td"><em parentName="strong">{`Primary snapshot condition`}</em></strong>{` has been set to DAYS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove replica snapshots after resource deletion`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Removes replica snapshots after the resource is deleted from AutoBackup. Can be set/visible if the `}<strong parentName="td"><em parentName="strong">{`Replica snapshot condition`}</em></strong>{` has been set to DAYS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cron expression`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The cron expression specifies at what intervals the backup should be performed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Backup time window duration in hours`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The time window where back up will be taken. Range 1-24 hours.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The start date specifies when the first backup will begin.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary snapshot condition`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines if primary snapshots should be removed after the specified number of days, or if the system should retain only the specified number of the newest snapshots.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary snapshot threshold`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`How many OR For how long the replica snapshots are retained.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Replica snapshot condition`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Determines if replica snapshots should be removed after the specified number of days, or if the system should retain only the specified number of the newest snapshots.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Replica snapshot threshold`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`How many OR For how long the replica snapshots are retained.`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`For Azure SAP HANA retention will not be kept according to the attached policy (AutoBackup triggers snapshots manually to keep the RPO but the default
retention for on-demand backup is 45 days - `}<a parentName="p" {...{
          "href": "https://docs.microsoft.com/en-us/azure/backup/backup-azure-sap-hana-database#run-an-on-demand-backup"
        }}>{`Azure documentation`}</a>{`)`}</p>
    </blockquote>
    <p><img alt="New Backup Policy" src={require("../../assets/configure/policies/rpo-policy-new.png")} /></p>
    <p>{`4a. In RPO type policy, the user can define different snapshot frequencies and retention schemes for them, in the scope of a single policy by clicking 'Add another RPO' button.
`}<img alt="New Backup Policy" src={require("../../assets/configure/policies/add-another-rpo.png")} /></p>
    <blockquote>
      <p parentName="blockquote">{`Please keep in mind, that all the RPO's within a single policy must be a multiple of the lowest defined RPO.`}</p>
    </blockquote>
    <p>{`Available frequencies:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`hours = 1 hour,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`days = 24 hours,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`weeks = 7 days = 168 hours,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`months = 30 days = 720 hours.`}</p>
      </li>
    </ul>
    <p>{`Valid retention scheme examples:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`4 hours, 2 days (48 hours), 2 weeks (336 hours)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`2 days (48 hours), 2 weeks (336 hours)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`2 days (48 hours), 3 months (2160 hours)`}</p>
      </li>
    </ul>
    <p>{`Invalid retention scheme examples (invalid values are highlighted):`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`2 days (48 hours), `}<inlineCode parentName="p">{`1 week (168 hours)`}</inlineCode>{`, 1 month (720 hours) - because 168 hours / 48 hours = 3.5`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`7 days (168 hours), `}<inlineCode parentName="p">{`2 months (2160 hours)`}</inlineCode>{` - because 2160 hours / 168 hours = 12.85`}</p>
      </li>
    </ul>
    <p>{`4b. In Time aware policy type, the user can define a cron expression that will be used to generate future events,
and the duration of the backup event (after this time backup event will be terminated - if the snapshot action is not in progress,
it will not be executed).
`}<img alt="Time Aware Policy" src={require("../../assets/configure/policies/time-aware-policy-new.png")} /></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`After filling all the required fields and clicking 'Save', the new policy should be visible on policies list.
`}<img alt="New Backup Policy" src={require("../../assets/configure/policies/policies-list-2.png")} /></li>
    </ol>
    <h2 {...{
      "id": "delete-the-policy"
    }}>{`Delete the policy`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Settings" from the navigation bar.`}</li>
      <li parentName="ol">{`Choose "Policies" tab.`}</li>
      <li parentName="ol">{`Click on "three dots" on the right side of the policy you want to delete, and select "Delete" option. The policy won't be removed if it is currently assigned to any resource.
`}<img alt="Delete policy" src={require("../../assets/configure/policies/policy-delete.png")} /></li>
      <li parentName="ol">{`Click on "Delete" button on the confirmation window.
`}<img alt="Delete policy" src={require("../../assets/configure/policies/policy-confirm.png")} /></li>
      <li parentName="ol">{`The policy should disappear from the policies list. If an error occurred then make sure that the policy is not assigned to the resources (detach policy from resource (see `}<a parentName="li" {...{
          "href": "/usage/operations-on-resources"
        }}>{`operations`}</a>{` page) or change resources' tags for tag-based backup).`}</li>
    </ol>
    <h2 {...{
      "id": "transition-from-rpo-policy-to-time-aware-policy"
    }}>{`Transition from RPO policy to Time Aware policy`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Please keep in mind, that all future events for the resource will be removed and AutoBackup will create new ones according
to the provided CRON from the policy.`}</p>
    </blockquote>
    <ol>
      <li parentName="ol">{`Create a new Time Aware policy (follow `}<a parentName="li" {...{
          "href": "/configuration/policy#add-a-new-policy"
        }}>{`guide`}</a>{`)`}</li>
      <li parentName="ol">{`Replace policy attached to the resources:`}<ul parentName="li">
          <li parentName="ul">{`change tag value if you use `}<a parentName="li" {...{
              "href": "/configuration/tags"
            }}>{`Tag-based`}</a>{` Backup,`}</li>
          <li parentName="ul">{`use `}<a parentName="li" {...{
              "href": "/usage/operations-on-resources#change-policy-and-setup"
            }}>{`UI`}</a>{` if you do not use Tag-based Backup.`}</li>
        </ul></li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Note: All previously taken snapshots will be removed based on a retention from the new policy.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      